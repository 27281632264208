
<template>
  <div class="main-content">

    <loading :active.sync="isLoading"
             :is-full-page="fullPage"></loading>

    <div class="row">
      <div class="col-md-12" style="padding-left: 50px;">
        <b-card class="card-icon  mb-30" style="padding-left: 50px;">
          <h3>Voucher usage report</h3>
          <hr/>
          <div class="row">
            <div class="col-md-6">
              <h4>Filters:</h4>
            </div>
            <div class="col-md-6">

            </div>
          </div>
          <hr/>
          <div class="row" style="margin-bottom: 20px;">
            <div class="col-md-4">
              <div>
                Date
              </div>

              <date-picker   :lang="lang" v-model="start_date" valueType="format" :first-day-of-week="1"></date-picker> to
              <date-picker   :lang="lang" v-model="end_date" valueType="format" :first-day-of-week="1"></date-picker> &nbsp;&nbsp;
            </div>
            <div class="col-md-3">
              <div>
                Voucher Usage
              </div>
              <select class="form-control" v-model="voucherUsage" >
                <option :value="vUsage" v-for="vUsage in voucherUsages"> {{ vUsage }}</option>
              </select>
            </div>
            <div class="col-md-3">
              <div>
                Voucher Type
              </div>
              <select class="form-control" v-model="voucherType">
                <option :value="vType" v-for="vType in voucherTypes"> {{ vType }}</option>
              </select>
            </div>
            <div class="col-md-2">
              <button class="btn btn-success float-right" v-b-modal.export-transactions> Export </button>
            </div>
          </div>
        </b-card>
      </div>
    </div>

    <b-modal id="export-transactions" title="Export voucher transactions" @cancel="true" @ok="exportToExcel"   centered>
      <form action="#" class="form-horizontal"  method="post">
        <input type="hidden" name="_token" />
        <div class="form-body">
          <div class="row" >
            <div class="col-md-12">
              <div class="form-group ">
                <label class="control-label ">Please enter your email address:</label> <br/>
                <div >
                  <input class="form-control" type="text"  v-model="email_address" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </b-modal>
  </div>
</template>
<script>

  import { mapGetters, mapActions } from "vuex";

  import Loading from 'vue-loading-overlay';
  // Import stylesheet
  import 'vue-loading-overlay/dist/vue-loading.css'
  import DatePicker from 'vue2-datepicker';
  import 'vue2-datepicker/index.css';
  import Multiselect from 'vue-multiselect'

export default {
  data() {
    return {
      email_address:"",
      reversal_comments:"",
      disabled:false,
      isLoading: false,
      fullPage: true,
      lang: {
        days: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        pickers: ['next 7 days', 'next 30 days', 'previous 7 days', 'previous 30 days'],
        placeholder: {
          date: 'Select Date',
          dateRange: 'Select Date Range'
        }
      },
      start_date:"all",
      end_date:"all",
      voucherUsage:"unused",
      voucherUsages:["unused","used"],
      voucherType:"Individual",
      voucherTypes:["Corporate","Individual"]
    };
  },
  computed:{
    isActived: function () {
      return this.pagination.current_page + 1;
    },
    pagesNumber: function () {
      if (!this.pagination.to) {
        return [];
      }
      var from = this.pagination.current_page - this.offset;
      if (from < 1) {
        from = 1;
      }
      var to = from + (this.offset * 2);
      if (to >= this.pagination.last_page) {
        to = this.pagination.last_page;
      }
      var pagesArray = [];
      while (from <= to) {
        pagesArray.push(from);
        from++;
      }
      return pagesArray;
    },
    permissions(){
      return localStorage.getItem("permissions");
    },
    role(){
      return localStorage.getItem("role");
    }
  },
  components: {
    Loading,
    DatePicker
  },
  methods: {
    ...mapActions(["exportCustomerVoucherUsageReport", "exportInstitutionVoucherUsageReport"]),

    exportToExcel(){
      if(this.voucherType == "Corporate"){
        this.exportInstitutionVoucherUsage()
      } else {
        this.exportCustomerVoucherUsage();
      }
    },
    exportCustomerVoucherUsage(){

      let self = this;
      this.isLoading = true;

      this.exportCustomerVoucherUsageReport({fromDate:this.start_date,toDate:this.end_date,voucherUsage:this.voucherUsage, username: this.email_address})
          .then(function () {
            self.isLoading = false;

            self.$bvToast.toast("Export request received. We'll email you the report once its ready", {
              title: 'Success',
              variant: 'success',
              autoHideDelay: 5000,
              appendToast: false
            });

          })
          .catch(function (error) {

            self.isLoading = false;

            console.log(error);
          });
    },
    exportInstitutionVoucherUsage(){

      let self = this;
      this.isLoading = true;

      this.exportInstitutionVoucherUsageReport({fromDate:this.start_date,toDate:this.end_date,voucherUsage:this.voucherUsage, username: this.email_address})
          .then(function () {
            self.isLoading = false;

            self.$bvToast.toast("Export request received. We'll email you the report once its ready", {
              title: 'Success',
              variant: 'success',
              autoHideDelay: 5000,
              appendToast: false
            });

          })
          .catch(function (error) {

            self.isLoading = false;

            console.log(error);
          });
    },

  }
};
</script>
